<template>
    <div style="padding-top: 8em;">
        <header-service :title="'LAYANAN OSS RBA'" :subtitle="'Pastikan legalitas perusahaan Anda sesuai dengan aturan terbaru. '"></header-service>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row>
                    <div class="col-md-4 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card sub-service-card">
                            <div class="card-body">
                                <img :src="detail.image" class="center mb-3 img-fluid" style="height: 200px;" v-if="detail.image">
                                <img src="@/assets/registrasi.png" class="center mb-3 img-fluid" style="height: 200px;" v-else>
                                <a href="">
                                </a>
                                <a href="" class="text-center">
                                    <h5>{{detail.title}}</h5>
                                </a>
                                <div class="w-100" style="height:180px">
                                    <p class="mb-1">Fasilitas :</p>
                                    <p class="mb-0 pb-0 mt-1" v-for="(syarat, index) in detail.service_item_facilities" :key="index">
                                        <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                    </p>
                                </div>
                                <div class="w-100" style="height:100px">
                                    <p class="mb-1">Estimasi waktu pengerjaan :</p>
                                    <p class="mb-0 pb-0 mt-1 bold" v-for="(syarat, index) in detail.service_item_syarats" :key="index">
                                        <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                    </p>
                                </div>
                            </div>
                            <div class="card-footer border-0 d-flex jutify-content-between">
                                <div class="price-tag w-75">
                                    <span class="small">Mulai</span>
                                    <p class="bold mb-0 mt-2">IDR {{detail.price }}</p>
                                </div>
                                <div class="w-25">
                                    <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank" class="button btn btn-danger mt-1">
                                        <v-icon color="#fff" small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <section class="mt-12">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold">
                            Pertanyaan Umum Seputar Layanan OSS RBA
                        </h3>
                    </v-col>
                    <v-col cols="12">
                        <v-expansion-panels
                        v-model="panel"
                        multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>Apa saja dokumen persyaratan untuk melakukan Penyesuaian Anggaran Dasar dan OSS RBA?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ol class="list-group list-group-numbered">
                                        <li>Persetujuan Para Pendiri dan Pengurus (draft kami siapkan)</li>
                                        <li>Akta &amp; SK Kemenkumham Pendirian dan seluruh perubahannya</li>
                                        <li>NPWP PT</li>
                                        <li>KTP dan NPWP seluruh pemegang saham dan pengurus 
                                            <br>Note:
                                            <ul class="list-group list-group-numbered">
                                            <li>Mohon dipastikan alamat yg tercantum pada KTP dan NPWP sama dan valid</li>
                                            <li>NPWP para pendiri dan pengurus sudah menggunakan format terupdate (terdapat NIK pada NPWP)</li>
                                            <li>Tidak memiliki laporan pajak terhutang baik pribadi maupun perusahaan</li>
                                            </ul>
                                        </li>
                                    </ol>                            
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Bagaimana penyesuaian Anggaran Dasar dilakukan? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                Penyesuaian dilakukan dengan mengubah isi Anggaran Dasar PT yang memuat bidang usaha sebagaimana tercantum dalam Maksud dan Tujuan agar sesuai dengan Klasifikasi Baku Lapangan Usaha Indonesia (KBLI) terbaru
                                              
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Mengapa Maksud dan Tujuan harus sesuai dengan KBLI terbaru?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    
                                Salah satu yang wajib diisi oleh pelaku usaha saat melakukan registrasi ke dalam sistem OSS RBA adalah data mengenai bidang usaha yang diatur dalam KBLI. Saat ini sistem OSS RBA menggunakan KBLI 2020 yang mengacu pada Peraturan Kepala BPS 02/2020. Sehingga bagi PT yang masih menggunakan KBLI versi lama pada Maksud dan Tujuan wajib menyesuaikan Anggaran dasarnya terlebih dahulu agar proses registrasi OSS RBA dapat dilakukan.
                              
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Apa saja dokumen persyaratan untuk melakukan Registrasi OSS RBA? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                Para pendiri dan pengurus perusahaan: <br>
                                <ol class="list-group list-group-numbered">
                                    <li>Untuk PT/Yayasan AKTA &amp; SK Kemenkumham bidang usaha yg tercantum di dalam akta pada sistem AHU sudah menggunakan KBLI 2017</li>
                                    <li>Untuk CV sudah teregistrasi dan memiliki SKT/SKPP dari Sistem Administrasi Badan Usaha (SABU) Kemenkumham</li>
                                    <li>Belum pernah melakukan registrasi ke dalam sistem OSS RBA</li>
                                    <li>NPWP Perusahaan</li>
                                    <li>KTP dan NPWP Penanggung Jawab Perusahaan</li>
                                </ol>                             
                
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Bila saya tidak atau belum memiliki persyaratan tersebut apa proses registrasi OSS RBA masih bisa dijalankan? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                
                                    Kamu harus memiliki persyaratan tersebut. Atau bila dokumennya sudah ada tapi sudah tidak berlaku atau bukan dalam format terbaru maka kamu harus datang ke kantor pemerintah yang berwenang. Misalnya untuk NPWP, kamu bisa datang ke Kantor Pelayanan Pajak (KPP) setempat. Proses registrasi OSS RBA hanya bisa dilakukan apabila dokumen persyaratan dinyatakan benar dan lengkap.
                          
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>Apa saja yang saya dapatkan setelah melakukan registrasi OSS RBA? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                Kamu akan mendapatkan Nomor Induk Berusaha (NIB) sebagai identitas untuk Perusahaan dan Surat Izin Usaha Perdagangan (SIUP) atau izin usaha lain yang sesuai dengan kegiatan usaha kamu. Khusus untuk NIB dan SIUP, kedua dokumen legalitas tersebut berlaku selama Perusahaan melakukan kegiatan usahanya. Dengan begitu kamu tidak perlu lagi memperpanjang masa berlakunya.    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"
import {service_item_api} from "@/backend-api/service_item_api"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Layanan OSS RBA',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=4`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted() { 
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>